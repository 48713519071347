import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d367225e = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _910d50ee = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _43967ca8 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _c6dbba1c = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _882140dc = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _7d7ddcb8 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _54f3793c = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _61722886 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _5a607286 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _28c1d627 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7a86ec8a = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _cde5d796 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _8528b8dc = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _6721d88a = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _ce984c16 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _dbd710c8 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _d20f9e5c = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _70d06d49 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _7213bca9 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _cb449908 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _749adbe0 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _c00e96d8 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _07b3a54e = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _79a3f842 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _fb79cab4 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _0baa4a4c = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _fbba2490 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _50dcc7a0 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _70aa9267 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _83e81074 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _2aabedc3 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _9a373e0a = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _ca93c806 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _27ca9bc7 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _6c449922 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _f7777ac8 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _1715d4bc = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _805113e4 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _263d2be4 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _6f21b51a = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _c51e0f36 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _37dfbc68 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _75ca272a = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _34bbaa92 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _335c8061 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _5d40960a = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _2ed117c3 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _52ccf1de = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _00d50558 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _2a070320 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _69cb9882 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _4199079e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a0e77d38 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _39362b6e = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _1fd7b77a = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _24848a25 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _cc764b40 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _2d8ba00b = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _b0ac3222 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _56f4687c = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _265ef62a = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _9f0733ba = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _550a7c0a = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _4519f179 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _791f3f14 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _1a36046e = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _60941e7a = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _6a555e6e = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _3470823a = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _23ef92ab = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _9048ac82 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _33003f6d = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _f95b8faa = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _ac66c046 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _d367225e,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _910d50ee,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _43967ca8,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _c6dbba1c,
    name: "calendar"
  }, {
    path: "/customer",
    component: _882140dc,
    name: "customer"
  }, {
    path: "/details",
    component: _7d7ddcb8,
    name: "details"
  }, {
    path: "/goals",
    component: _54f3793c,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _61722886,
    name: "maisoku"
  }, {
    path: "/media",
    component: _5a607286,
    name: "media"
  }, {
    path: "/privacy",
    component: _28c1d627,
    name: "privacy"
  }, {
    path: "/project",
    component: _7a86ec8a,
    name: "project"
  }, {
    path: "/response",
    component: _cde5d796,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _8528b8dc,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _6721d88a,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _ce984c16,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _dbd710c8,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _d20f9e5c,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _70d06d49,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _7213bca9,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _cb449908,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _749adbe0,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _c00e96d8,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _07b3a54e,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _79a3f842,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _fb79cab4,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _0baa4a4c,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _fbba2490,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _50dcc7a0,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _70aa9267,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _83e81074,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _2aabedc3,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _9a373e0a,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _ca93c806,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _27ca9bc7,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _6c449922,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _f7777ac8,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _1715d4bc,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _805113e4,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _263d2be4,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _6f21b51a,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _c51e0f36,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _37dfbc68,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _75ca272a,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _34bbaa92,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _335c8061,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _5d40960a,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _2ed117c3,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _52ccf1de,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _00d50558,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _2a070320,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _69cb9882,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _4199079e,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _a0e77d38,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _39362b6e,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _1fd7b77a,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _24848a25,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _cc764b40,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _2d8ba00b,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _b0ac3222,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _56f4687c,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _265ef62a,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _9f0733ba,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _550a7c0a,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _4519f179,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _791f3f14,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _1a36046e,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _60941e7a,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _6a555e6e,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _3470823a,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _23ef92ab,
    name: "property-property",
    children: [{
      path: "contact",
      component: _9048ac82,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _33003f6d,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _f95b8faa,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _ac66c046,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
